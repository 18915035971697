import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import Container from './Container'
import Layout from './Layout'
import PageHeader from './PageHeader'
import RewardsHeader from './RewardsHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'

const RewardsLayout = ({ children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "Rewards" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
    }
  `)

  return (
    <Layout {...props}>
      <SEO />
      <PageHeader
        imageMobile={data.allContentfulPageContext.edges[0].node.heroImageMobile}
        imageDesktop={data.allContentfulPageContext.edges[0].node.heroImageDesktop}
      >
        <RewardsHeader />
      </PageHeader>
      <StickyContainer
        stickyNavLinks={
          <>
            <Link to="/rewards/" activeClassName="active-page-link">
              Introduction
            </Link>
            <Link to={`/rewards/level-one/`} activeClassName="active-page-link">
              Level One
            </Link>
            <Link to={`/rewards/level-two/`} activeClassName="active-page-link">
              Level Two
            </Link>
            <Link to={`/rewards/level-three/`} activeClassName="active-page-link">
              Level Three
            </Link>
            <Link to={`/rewards/level-four/`} activeClassName="active-page-link">
              Level Four
            </Link>
            <Link to={`/rewards/bonus-trip/`} activeClassName="active-page-link">
              Bonus Trip 2024
            </Link>
            <Link to={`/rewards/faqs/`} activeClassName="active-page-link">
              FAQs
            </Link>
          </>
        }
      >
        <Container className="wrap section">{children}</Container>
      </StickyContainer>
    </Layout>
  )
}

export default RewardsLayout
